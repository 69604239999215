import { Pipe, PipeTransform } from '@angular/core';
import { BR_TAG, NEW_LINE_CHARACTER } from 'src/app/core/model/constants';

@Pipe({
  name: 'toInnerHtmlCompatible'
})
export class ToInnerHtmlCompatiblePipe implements PipeTransform {

  transform(value: string): unknown {
    value = value?.split(NEW_LINE_CHARACTER).join(BR_TAG)
    return value;
  }

}
