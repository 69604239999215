import { Component, ElementRef, EventEmitter, HostListener, Input, Output, ViewChild, ViewContainerRef, ViewEncapsulation } from '@angular/core';
import {  GridComponent,GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { CompositeFilterDescriptor, distinct, filterBy, State } from '@progress/kendo-data-query';
import { Observable,  of } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { NO_WEB_LINK_DOCUMENT, COPY_RIGHT, PDF_EXTENSION, SEARCH_ALL_COLUMNS_TEXT, WEB_LINK_01 } from 'src/app/core/model/constants';
import { ExpandColumnDescriptor, ExportDocumentType, GIRGridCellEvent } from 'src/app/core/model/data-grid.model';
import { CommonDataService } from 'src/app/core/services/common-data.service';
import { PrintGridComponent } from '../print-grid/print-grid.component';



@Component({
  selector: 'app-data-grid',
  templateUrl: './data-grid.component.html',
  styleUrls: ['./data-grid.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DataGridComponent {
  @Input() elementsMeta: any[] = [];
  @Input() elements: any[] = [];
  @Input() heightOffset: number;
  @Input()  haveDocumentsLinks : boolean = false;
  @Input()  takeMaximumPageSize : boolean = false;
  @Input() gridExportFileName: string = "Data Grid";
  @Input() pdfTitle : string =''
  @Input() columnDescriptor : ExpandColumnDescriptor = { isExpanded : false , columnName : '' ,columnSize : 0 , otherColumnSize : 0 } ;
  @Input() gridHeight : number = 400
  @Input() isPdfEnabled : boolean =  false
  @Input() preventCopy : boolean = false
  public excelTitle = this.pdfTitle  
  public copyRight : string= COPY_RIGHT;

  
  public finalGridData: GridDataResult;
  public elementsMetaChanged = this.elements
  public columnFilterValue : string = ''
  public filter: CompositeFilterDescriptor = { logic: "and", filters: [] };
  public allColumnfilter: CompositeFilterDescriptor = { logic: "and", filters: [] };
  public gridData: any[] = filterBy(this.elements, this.filter);
  @Output() handleCellClickEvent = new EventEmitter<Object>();
  @ViewChild('KGrid') grid: GridComponent;

  

  public searchInAllColumn : string = SEARCH_ALL_COLUMNS_TEXT

  public pdfExtention : string = PDF_EXTENSION
  public webLinkExtention : string = WEB_LINK_01
  public noLinkAndDocument : string= NO_WEB_LINK_DOCUMENT
  public autoPrint : boolean = false;  
  public value : Observable<any>;

  
  public buttonCount : number = 5;
  public initialGridRecordsNumber : number = 50
  public initialPagerRecordsNumber : number =  10
  public totalSize : number =10000;
  public gridState: State = { skip: 0, take: this.initialGridRecordsNumber };
  public pagerState: State = { skip: 0, take: this.initialPagerRecordsNumber};
  public exportDocumentType : ExportDocumentType 
  public slicedData: any[]; 
  @ViewChild('printFile', { read: ViewContainerRef }) private viewRef!: ViewContainerRef


  
  constructor(private commonDataService: CommonDataService
              , private elementRef: ElementRef) { }

  ngOnInit() {
    this.gridData = this.elements;

    this.triggerDataChange();

    this.elementRef.nativeElement.style.setProperty('--grid-height-offset', (this.heightOffset.toString() + 'px'));
    this.commonDataService.clearAllFilter$.subscribe(x => {
      this.filterChange({ logic: "and", filters: [] });
      this.filter = { logic: 'and', filters: [] };
    })
    this.allColumnfilter = { logic: "or", filters: [] }

  }
  
  ngOnChanges(){
    this.gridData = this.elements;
    this.triggerDataChange();
  }

  public triggerDataChange(){
     this.totalSize = this.gridData.length
     this.pagerState= { skip: 0, take:  this.totalSize > this.initialPagerRecordsNumber ? this.initialPagerRecordsNumber : this.totalSize };
     this.sliceTotalData();
     this.loadData();
  }

  public onPagerChange(e, grid: GridComponent) {
    this.pagerState.skip = e.skip;
    this.pagerState.take = e.take;
    this.sliceTotalData();
    grid?.scrollTo({ row: 0 });
  }

  public sliceTotalData() {
    this.slicedData = this.gridData.slice( this.pagerState.skip, this.pagerState.skip + this.pagerState.take)
    this.loadData(true);
  }

  public pageChange(event: PageChangeEvent): void {
    this.gridState.skip = event.skip;
    this.loadData();
  }

  private loadData(refresh : boolean = false): void {
    this.finalGridData = {
      data : this.slicedData.slice( this.gridState.skip, this.gridState.skip + this.gridState.take),
      total: this.slicedData.length,
    };
    if(refresh)
    this.grid?.scrollTo({ row: 0 });
  }


  public filterChange(filter: CompositeFilterDescriptor): void {
    this.filter = filter;
    this.columnFilterValue =''
    this.gridData = filterBy(this.elements, filter);
    
    this.triggerDataChange();
  }

  public allColumnFilterMethod(inputdata : any){
    of(inputdata)
    .pipe(debounceTime(20),distinctUntilChanged())
    .subscribe(inputValue=>{
      if(inputValue?.length>0)
      {
        if(this.filter.filters?.length>0)
          this.filter = { logic: "and", filters: [] };
        let actucalFilters =[]
        this.elementsMeta.forEach(x=> actucalFilters.push({ field: x?.columnField, operator: 'contains', value: inputValue}))
        this.gridData  =  filterBy(this.elements, { logic: 'or', filters: actucalFilters });
      }
      else
        this.gridData= this.elements;

        this.value = of(inputValue)

        this.triggerDataChange();
      })
  }

  public distinctPrimitive(fieldName: string): unknown[] {
    return distinct(this.elements, fieldName).map((item) => item[fieldName]);
  }

  handleCellClick(columnId: number, columnName: any, dataElement: string, cellDescription: string,
    dataElementId: number, IDAEOIReporting: string, qiDashboardId: string, columnNameQiQDD: string,
    IsCountrySpecific: boolean, dataElementNameQDD: string, jurisdictionName: string,
    newsId : string,newsType : string,jurisdictionNameNews : string) {
    let finalValue: GIRGridCellEvent =
    {
      columnId: columnId,
      columnName: columnName,
      dataElementId: dataElementId,
      cellDescription: cellDescription,
      dataElement: dataElement,
      IDAEOIReporting: IDAEOIReporting,
      id: qiDashboardId,
      columnNameQiQDD: columnNameQiQDD,
      IsCountrySpecific: IsCountrySpecific,
      dataElementNameQDD: dataElementNameQDD,
      jurisdictionName: jurisdictionName,
      newsType : newsType,
      newsId : newsId,
      jurisdictionNameNews : jurisdictionNameNews
    }
    this.handleCellClickEvent.emit(finalValue)
  }

  public exportToExcel() {
    this.exportDocumentType = ExportDocumentType.Excel
    this.sendToPopUp();
  }

  public exportToPdf() {
    this.exportDocumentType = ExportDocumentType.Pdf
    this.sendToPopUp();
  }

  public printGrid() {
    this.exportDocumentType = ExportDocumentType.Pdf
    this.sendToPopUp();
  }

  public sendToPopUp(){
      this.viewRef.clear(); 
      let ref = this.viewRef.createComponent(PrintGridComponent);
      let obj = (ref.instance as PrintGridComponent);
      obj.elementsMeta = this.elementsMeta;
      obj.elements = this.slicedData;
      obj.columnDescriptor = this.columnDescriptor
      obj.gridExportFileName = this.gridExportFileName
      obj.pdfTitle = this.pdfTitle
      obj.exportDocumentType = this.exportDocumentType
  }

  ngOnDestroy(){
    this.viewRef.clear();
  }

  public clearReference(val : any){
      this.viewRef.clear();
  }

  @HostListener('window:selectstart', ['$event']) 
  avoidCopy(event){ 
    if(this.preventCopy)
      event.preventDefault();
  }
  
}
