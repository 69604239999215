<div class="mt-2 data">
    <app-data-grid #qiQddGrid [elements]="elements"  [elementsMeta]="elementsMeta" [heightOffset]="heightOffset"
    [preventCopy]="preventCopy" (handleCellClickEvent)="handleCellClickEvent($event)"  gridExportFileName="QI QDD"></app-data-grid>
</div>

<app-pop-up [dispPopUp]="ViewPopup" [displayHeaderSection]=false (closedPopUpEvent)="closePopup($event)">
    <h4 class="mb-4 popupSepcificTitle">{{popupSpecificTitle}}</h4>
    <div class="mb-4" [innerHTML]="cellDescription | toInnerHtmlCompatible"></div>
    <ng-container *ngIf="cellIsOfDetailsType">
        <ng-container *ngIf="isCountrySpecific" >
            <div class="dropDownSection my-2">
                <label class="me-3" for="">Select Jurisdiction </label>
                <kendo-dropdownlist class="col-4 px-2" [data]="availableCountries"
                [textField]="'jurisdictionName'" [valueField]="'jurisdictionName'"  
                [(ngModel)]="selectedCountry" (valueChange)="handleCountryChange($event)" >
                </kendo-dropdownlist>
            </div>
        </ng-container>
        <app-file-link-display [docList]="attachementList" [tileType]="TileCode.QIQDD"></app-file-link-display>      
        <div class="mb-4">
            <p class="list-title">Enterpise Policy</p>
            <P> {{enterprisePolicy}} </P>            
        </div>
        <ng-container *ngIf="(GIRUserRole == GIRUserRole.CTM) ? true : false">
            <button class="btn btn-action-primary text-nowrap mb-4" (click)="publishEnterPrisePolicy()">
                Publish Enterprise Policy</button>
        </ng-container>
            <br>
      </ng-container>
</app-pop-up>

<app-pop-up [dispPopUp]=" saveEnterprisePolicy" [displayHeaderSection]=false (closedPopUpEvent)="savePopup($event)">
    <app-enterprise-policy-save 
        [jurisdictionId]="jurisdictionIdObs$ | async"
        [dataElementId]="dataElementIdObs$ | async"
        [tileService]="tileService!">
    </app-enterprise-policy-save>
</app-pop-up>